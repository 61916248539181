<template>
  <v-app>
     <v-content>
       <v-container fluid fill-height>
         <v-layout align-center justify-center>
           <v-flex xs12 sm8 md4>
                <v-container grid-list-xs align-center justify-center class="text-center">
                    <h1>Christmas Crazy Quests & Quizes</h1>
                </v-container>
                 <v-card class="elevation-26">
                    <v-card-text>
                       <v-form>
                          <v-text-field
                             prepend-icon="mdi-account"
                             name="name"
                             label="Team Name"
                             id="name"
                             type="text"
                          ></v-text-field>
                          <v-text-field
                             id="password"
                             prepend-icon="mdi-lock"
                             name="roomPassword"
                             label="Unique One-Time Login Security ID"
                             type="password"
                          ></v-text-field>
                       </v-form>
                    </v-card-text>
                    <v-card-actions>
                       <v-spacer></v-spacer>
                       <v-btn :loading="loading" color="primary" @click="login">Login</v-btn>
                       <v-spacer></v-spacer>
                    </v-card-actions>
                 </v-card>
              </v-flex>
           </v-layout>
           <v-alert v-show="error" :value="true" :duration="5000" type="error" :style="{'position': 'absolute', 'bottom': '10px', 'right': '10px'}" transition="slide-x-reverse-transition">
            {{errorMessage}}
         </v-alert>
         </v-container>
         <!-- Add the v-alert component here -->
      </v-content>
  </v-app>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
    name: "logIn",
    props: {
        source: String
    },
    data() {
        return {
            error: false,
            loading: false,
            errorMessage: ""
        };
    },
    created: {
        checkIfPlayer () {
            if(!localStorage.getItem("playerId")) return
            this.loading = true;
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/reLogin",
                method: "post",
                data: qs.stringify({
                    id: localStorage.getItem("playerId"),
                })
            }).then(() => {
                this.$emit("hideLogin", false);
            }).catch(() => {
                this.loading = false;
                localStorage.removeItem("playerId")
            });
        }
    },
    methods: {
        login() {
            this.loading = true;
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/newLogin",
                method: "post",
                data: qs.stringify({
                    name: document.getElementById("name").value,
                    password: document.getElementById("password").value
                })
            }).then((response) => {
                localStorage.setItem("playerId", response.data.id);
                this.$emit("hideLogin", false);
            }).catch((err) => {
                console.log(err);
                this.loading = false;
                this.error = true;
                this.errorMessage = err.response.data.error;
                setTimeout(() => {
                    this.error = false;
                }, 5000);
            });
        }
    },
}
</script>

<style></style>

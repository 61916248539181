<template>
  <v-app>
    <v-main class="app">
      <v-overlay :absolute="absolute" :opacity="0.9" :value="overlay" justify-center align-center>
        <h2 class="font-weigt-bold" mx-auto justify-content-center text-sm-center text-xs-center>
          An error seems to have occurred, please reload the page.
        </h2>
      </v-overlay>
      <login v-if="(screen === 'login')" @hideLogin="hideLoginMethod" />
      <lobby v-else-if="(screen === 'lobby')" :lobbyMessage='lobbyMessage' />
      <leaderboard v-else-if="(screen === 'leaderboard')" :Leaderboard='Leaderboard' />
      <game v-else-if="(screen === 'game')" :screen='screen' @gotoLobby='gotoLobby' />
      <dashboard v-else-if="(screen === 'dashboard')" :Players='Players' />
    </v-main>
  </v-app>
</template>

<script>
import login from './components/login';
import lobby from './components/lobby';
import leaderboard from './components/leaderboard';
import game from './components/game'
import dashboard from './components/dashboard'
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'App',

  components: {
    login,
    lobby,
    leaderboard,
    game,
    dashboard
  },

  data() {
    return {
      screen: '',
      absolute: true,
      overlay: false,
      lobbyMessage: 'Waiting for game to start',
      Leaderboard: [],
      Players: [],
      ws: new WebSocket('wss://quiznightapi.coolkid2342.co.uk/ws')
    }
  },

  created: function connect() {
    this.ws.onclose = () => {
      if (this.overlay === false) this.overlay = true
    }
    this.ws.onerror = () => {
      if (this.overlay === false) this.overlay = true
    }
    this.ws.onopen = () => {
      this.overlay = false
      // Dash Request Data
      setTimeout(() => {
        setInterval(() => {
          if (this.screen === 'dashboard') return this.ws.send(JSON.stringify({ data: 'imDashboard' }))
        }, 500);
      }, 1000);
    }
    this.ws.onmessage = (msg) => {
      var message = JSON.parse(msg.data)
      if (this.screen === 'dashboard') {
        if (!message.players) {
          console.log("{data: 'imDashboard'}")
        } else {
          this.Players = message.players;
        }
      }
      else {
        if (!localStorage.getItem("playerId")) return;
        if (message?.action === "gotoGamePad") return this.screen = 'game'
        if (message?.action === "gotoLobby") {
          this.screen = 'lobby'
          console.log(message.lobbyMessage[localStorage.getItem("playerId")])
          if (message.lobbyMessage[localStorage.getItem("playerId")] === undefined || message.lobbyMessage[localStorage.getItem("playerId")] === null) return this.lobbyMessage = "Waiting for game to start"
          this.lobbyMessage = message.lobbyMessage[localStorage.getItem("playerId")]
        }
        if (message?.action === "gotoLeaderboard") {
          this.Leaderboard = [{ player: message.leaderboard[0].name, score: message.leaderboard[0].points }, { player: message.leaderboard[1].name, score: message.leaderboard[1].points }, { player: message.leaderboard[2].name, score: message.leaderboard[2].points }]
          console.log(this.Leaderboard)
          this.screen = 'leaderboard'
        }
        if (message?.action === "changeLobbyMessage") {
          this.lobbyMessage = message.lobbyMessage
        }
        else {
          console.log("Action not in code")
        }
      }
    }

    // Relogin Handler

    if (localStorage.getItem("playerId")) {
      this.loading = true;
      if (localStorage.getItem("playerId") === 'DashboardUser123') this.screen = 'dashboard'
      else {
        axios({
          url: "https://quiznightapi.coolkid2342.co.uk/reLogin",
          method: "post",
          data: qs.stringify({
            id: localStorage.getItem("playerId"),
          })
        }).then(() => {
          this.screen = 'lobby'
        }).catch(() => {
          this.loading = false;
          this.screen = 'login'
          localStorage.removeItem("playerId")
        });
      }
    }
    else {
      this.screen = 'login'
    }


  },
  methods: {
    hideLoginMethod: function () {
      this.screen = 'lobby'
      return true
    },
    gotoLobby: function () {
      console.log()
      this.screen = 'lobby'
      return true
    }
  }
}

</script>

<style>
html {
  overflow-y: hidden;
  overflow: hidden;
}

#app {
  background: #131313;
  color: white;
}
</style>
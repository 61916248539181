<template>
  <v-app>
     <v-content>
        <v-container fluid fill-height>
           <v-layout class="text-center" align-center justify-center row>
               <v-flex column>
                  <p>
                     <h2 class="font-weigt-bold" mx-auto justify-content-center>
                        {{lobbyMessage}}
                     </h2>
                  <!-- eslint-disable-next-line -->
                  </p>
                  <p>
                     <v-progress-circular
                     :size="25"
                     color="primary"
                     height="200"
                     indeterminate
                     ></v-progress-circular>
                  </p>
               </v-flex>
            </v-layout>
        </v-container>
     </v-content>
  </v-app>
</template>
<script>
export default {
  name: 'GameView',
  props: {
    source: String,
    lobbyMessage: String
  }
}
</script>

<style></style>

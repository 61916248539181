<template>
    <v-container>
          <v-col cols="14" v-for="(player, index) in Players" :key="index" class="my-1">
            <v-row full-width>
                <v-col cols="3">
                    {{player.name}}
                </v-col>
                    <v-btn color="red" style="position: relative; top: 5px;" @click="decrease(player)"><b>-</b></v-btn>
                    <v-container style="width: 99px">
                        <v-text-field rounded="true" background-color="white" disabled="true" width="14%" style="position: relative; right: 40px; bottom: 20px" :value="player.points" />
                    </v-container>
                    <v-btn color="green" style="position: relative; top: 5px; right: 78px" @click="increase(player)">+</v-btn>
                <v-col cols="6" style="position: relative; right: 78px">Current Answer: {{player.answer}}</v-col>
            </v-row>
        </v-col>
    <v-container fill-height style="position: absolute; bottom: 10px; height: 66px" >
        <v-btn bottom="0" :color="colorA" :loading="loadingA" @click="answerA">A</v-btn>
        <v-spacer></v-spacer>
        <v-btn bottom="0" :color="colorB" :loading="loadingB" @click="answerB">B</v-btn>
        <v-spacer></v-spacer>
        <v-btn bottom="0" :color="colorC" :loading="loadingC" @click="answerC">C</v-btn>
        <v-spacer></v-spacer>
        <v-btn bottom="0" :color="colorD" :loading="loadingD" @click="answerD">D</v-btn>
        </v-container>
    </v-container>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
    name: 'DashBoard',
    props: {
        source: String,
        Players: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            buttons: ['A', 'B', 'C', 'D'],
            loadingA: false,
            colorA: undefined,
            loadingB: false,
            colorB: undefined,
            loadingC: false,
            colorC: undefined,
            loadingD: false,
            colorD: undefined,
        }
    },
    mounted() {
        this.isMobile = window.innerWidth < 600;
        setInterval(() => {
            // console.log(this.Players)
        }, 1000);
    },
    methods: {
        answerA() {
            this.loadingA = true
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/answerA",
                method: "post"
            }).then(() => {
                this.loadingA = false;
                this.colorA = 'green';
                this.colorB = '';
                this.colorC = '';
                this.colorD = '';
            });
        },
        answerB() {
            this.loadingB = true;
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/answerB",
                method: "post"
            }).then(() => {
                this.loadingB = false
                this.colorA = '';
                this.colorB = 'green';
                this.colorC = '';
                this.colorD = '';
            });
        },
        answerC() {
            this.loadingC = true;
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/answerC",
                method: "post"
            }).then(() => {
                this.loadingC = false
                this.colorA = '';
                this.colorB = '';
                this.colorC = 'green';
                this.colorD = '';
            });
        },
        answerD() {
            this.loadingD = true;
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/answerD",
                method: "post"
            }).then(() => {
                this.loadingD = false
                this.colorA = '';
                this.colorB = '';
                this.colorC = '';
                this.colorD = 'green';
            });
        },
        decrease(player) {
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/decreasePoint",
                method: "post",
                data: qs.stringify({
                    playerId: player._id
                })
            });
        },
        increase(player) {
            axios({
                url: "https://quiznightapi.coolkid2342.co.uk/increasePoint",
                method: "post",
                data: qs.stringify({
                    playerId: player._id
                })
            });
        }
    }
}
</script>

<style>
.v-col {
    background: linear-gradient(to bottom, #64b3f4 0%, #c2e59c 100%);
}
</style>

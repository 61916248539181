<template>
  <v-container>
    <v-row>
      <!-- eslint-disable-next-line -->
      <v-col cols="12" v-if="isMobile" v-for="(button, index) in buttons" :key="index" class="my-11">
        <v-btn color="primary" width="100%" height="300%" @click="answer(button)">
          <h1>{{ button }}</h1>
        </v-btn>
        <p />
      </v-col>
      <!-- eslint-disable-next-line -->
      <v-col cols="6" v-if="!isMobile" v-for="(button, index) in buttons" :key="index" class="my-16">
        <v-btn color="primary" width="100%" height="360%" @click="answer(button)">
          <h1>{{ button }}</h1>
        </v-btn>
        <p />
      </v-col>
      <v-overlay :value="loadingOverlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import qs from 'qs'

export default {
  name: 'GamePage',
  props: {
    source: String
  },
  data() {
    return {
      buttons: ['A', 'B', 'C', 'D'],
      isMobile: false,
      loadingOverlay: false
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 600;
  },
  methods: {
    answer(button) {
      this.loadingOverlay = true
      axios({
        url: "https://quiznightapi.coolkid2342.co.uk/answer",
        method: "post",
        data: qs.stringify({
          playerId: localStorage.getItem("playerId"),
          answer: button
        })
      }).then(() => {
        this.$emit("gotoLobby", false);
        setTimeout(() => {
          this.loadingOverlay = false
        }, 4000);
      }).catch(() => {
        this.$emit("gotoLobby", false);
        setTimeout(() => {
          this.loadingOverlay = false
        }, 4000);
      });
    }
  }
}
</script>

<style>
.v-col {
  background: linear-gradient(to bottom, #64b3f4 0%, #c2e59c 100%);
}
</style>

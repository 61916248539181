<template>
    <v-app>
        <v-content>
            <v-flex fill-height justify-center align-center>
                <v-container fluid fill-height align-center justify-center>
                    <v-data-table :headers="headers" :items="Leaderboard" :search="search" hide-default-footer="false" disable-sort="true" disable-pagination="true" dense="true"/>
                    <v-overlay :absolute="absolute" :opacity="0" justify-center align-center/>
                </v-container>
            </v-flex>
        </v-content>
    </v-app>
</template>
<script>
export default {
    name: 'LeaderBoard',
    props: {
        source: String,
        Leaderboard: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                { text: 'Player', value: 'player' },
                { text: 'Score', value: 'score' }
            ]
        }
    }
}
</script>

<style>

</style>
